const Body = () => {
    return (
        <div className="body">
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
            Hello
        </div>
    )
}

export default Body;