import { Pages } from "../enums/pages";

interface IHeaderProps {
    onItemClick: (page: Pages) => void;
}

const Header = (props: IHeaderProps) => {
    return (
        <div className="app-title">
        <h3 className='title sticky-top'>SAM TAMBORELLO</h3>
        <nav className='navbar navbar-expand-lg'>
          <div className='container-fluid'>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <span className="nav-link color-black" onClick={() => alert('srf')} aria-current="page">ART</span>
                </li>
                <li className="nav-item dropdown">
                  <span className="nav-link dropdown-toggle color-black" data-bs-toggle="dropdown" aria-expanded="false">
                    WORK
                  </span>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item">RECENT</a></li>
                    <li><a className="dropdown-item">EVENTS</a></li>
                    <li><a className="dropdown-item">VIDEO</a></li>
                    <li><a className="dropdown-item">BTS CAMERA & GRIP</a></li>
                    <li><a className="dropdown-item">FANDOM</a></li>
                    <li><a className="dropdown-item">UNICYCLE FESTIVAL</a></li>
                    <li><a className="dropdown-item">SUICIDE SEATS: ROLLER DERBY</a></li>
                    <li><a className="dropdown-item">STILL LIFE</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link color-black">ABOUT</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    )
}

export default Header;