import React, { useState } from 'react';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Body from './components/body';
import { Pages } from './enums/pages';

function App() {
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.recent);

  return (
    <div className="App App-header">
      <Header onItemClick={setCurrentPage} />
        <Body />
      <Footer />

    </div>
  );
}

export default App;
